<template>
  <div class="sellerCenter">
    <!-- 导航栏 -->
    <div class="navbar">
      <div class="navbarLeft">
        <div class="navbarLogo">
          <img src="../../assets/img/credis-logo.png"
               alt="">
        </div>
        <span @click="$router.push('/F_home')">卖家中心</span>
      </div>
      <div class="navbarRight">
        <div>联系我们: service@crediscounts.com</div>
        <div>操作指导</div>
        <div @click="$router.push('/F_login')">登录</div>
        <div @click="$router.push('/F_register')">注册</div>
      </div>
    </div>
    <!-- 中间bannar -->
    <div class="banner">
      <div class="bannerBox">
        <h1>CREDISCOUNTS</h1>
        <h2>你怎么那么幸运关注了我们！</h2>
        <p>服务于全球商家和用户的deals网站，致力于提供优质的亚马逊站外推广，为广大亚马逊商家提供最便捷高效的deals服务和新品推广。</p>
        <p>拥有全球百万级活跃真实买家用户和国外各大社交平台海量红人资源，听说您也是资深亚马逊卖家，那就加入我们吧！</p>
        <div class="bannerBtn">
          <el-button @click="$router.push('/F_register')">立即注册</el-button>
          <el-button @click="$router.push('/F_home')">促销主页</el-button>
        </div>
      </div>
    </div>
    <!-- 选择理由 -->
    <div class="reasonBox">
      <h3>选择我们的8大理由
        <div class="xian"></div>
      </h3>
      <div class="row">
        <div class="rowLeft">
          <img src="../../assets/img/zzpic.jpg"
               alt="">
        </div>
        <div class="rowRight">
          <div class="rightBox"
               v-for="(reasonItem, index) in reasonList"
               :key="index">
            <p class="p1">
              <svg viewBox="0 0 16 16"
                   class="bi bi-globe"
                   fill="currentColor"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M1.018 7.5h2.49c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5zM2.255 4H4.09a9.266 9.266 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.024 7.024 0 0 0 2.255 4zM8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm-.5 1.077c-.67.204-1.335.82-1.887 1.855-.173.324-.33.682-.468 1.068H7.5V1.077zM7.5 5H4.847a12.5 12.5 0 0 0-.338 2.5H7.5V5zm1 2.5V5h2.653c.187.765.306 1.608.338 2.5H8.5zm-1 1H4.51a12.5 12.5 0 0 0 .337 2.5H7.5V8.5zm1 2.5V8.5h2.99a12.495 12.495 0 0 1-.337 2.5H8.5zm-1 1H5.145c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12zm-2.173 2.472a6.695 6.695 0 0 1-.597-.933A9.267 9.267 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM1.674 11H3.82a13.651 13.651 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm8.999 3.472A7.024 7.024 0 0 0 13.745 12h-1.834a9.278 9.278 0 0 1-.641 1.539 6.688 6.688 0 0 1-.597.933zM10.855 12H8.5v2.923c.67-.204 1.335-.82 1.887-1.855A7.98 7.98 0 0 0 10.855 12zm1.325-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm.312-3.5h2.49a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.91 4a9.277 9.277 0 0 0-.64-1.539 6.692 6.692 0 0 0-.597-.933A7.024 7.024 0 0 1 13.745 4h-1.834zm-1.055 0H8.5V1.077c.67.204 1.335.82 1.887 1.855.173.324.33.682.468 1.068z"></path>
              </svg>
              {{reasonItem.title}}
            </p>
            <p class="p2">{{reasonItem.text}}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 获取测试 -->
    <div class="testBox">
      <h3>轻松获取产品REVIEW测评
        <div class="xian"></div>
      </h3>
      <div class="mytipul">
        <div class="mytipulBox">
          <span>
            <svg viewBox="0 0 16 16"
                 class="bi bi-wallet-fill"
                 fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"></path>
              <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"></path>
            </svg>
          </span>
          <p>产品付款发布</p>
        </div>
        <div class="mytipulImg">
          <img src="../../assets/img/liuc.png"
               alt="">
        </div>
        <div class="mytipulBox">
          <span>
            <svg viewBox="0 0 16 16"
                 class="bi bi-wallet-fill"
                 fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"></path>
              <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"></path>
            </svg>
          </span>
          <p>产品付款发布</p>
        </div>
        <div class="mytipulImg">
          <img src="../../assets/img/liuc.png"
               alt="">
        </div>
        <div class="mytipulBox">
          <span>
            <svg viewBox="0 0 16 16"
                 class="bi bi-wallet-fill"
                 fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"></path>
              <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"></path>
            </svg>
          </span>
          <p>产品付款发布</p>
        </div>
        <div class="mytipulImg">
          <img src="../../assets/img/liuc.png"
               alt="">
        </div>
        <div class="mytipulBox">
          <span>
            <svg viewBox="0 0 16 16"
                 class="bi bi-wallet-fill"
                 fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"></path>
              <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"></path>
            </svg>
          </span>
          <p>产品付款发布</p>
        </div>
        <div class="mytipulImg">
          <img src="../../assets/img/liuc.png"
               alt="">
        </div>
        <div class="mytipulBox">
          <span>
            <svg viewBox="0 0 16 16"
                 class="bi bi-wallet-fill"
                 fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"></path>
              <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"></path>
            </svg>
          </span>
          <p>产品付款发布</p>
        </div>
        <div class="mytipulImg">
          <img src="../../assets/img/liuc.png"
               alt="">
        </div>
        <div class="mytipulBox">
          <span>
            <svg viewBox="0 0 16 16"
                 class="bi bi-wallet-fill"
                 fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"></path>
              <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"></path>
            </svg>
          </span>
          <p>产品付款发布</p>
        </div>
      </div>
    </div>
    <!-- 功能服务 -->
    <div class="serviceBox">
      <h3>Crediscounts功能及服务
        <div class="xian"></div>
      </h3>
      <div class="myquestion">
        <el-collapse accordion>
          <el-collapse-item v-for="(myquestionItem, i) in myquestionList"
                            :key='i'>
            <template slot="title">
              <i class="header-icon el-icon-user-solid"></i>一致性 Consistency
            </template>
            <div>与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；</div>
            <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div>
          </el-collapse-item>
        </el-collapse>
        <div class="myquestionImg">
          <img src="../../assets/img/qu.png"
               alt="">
        </div>
      </div>
    </div>
    <div class="launch">想在Crediscounts上投放Featured Deals 和 Banner广告？请直接联系service@crediscounts.com</div>
    <div class="treaty">Copyright © Crediscounts:Amazon free samples，discount coupon codes-deals website All rights reserved. </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: '1',
      reasonList: [
        { path: '', title: '支持全球站点', text: '美国，英国，德国，西班牙，意大利，加拿大，法国，日本' },
        { path: '', title: '精准平台流量', text: '覆盖各大社交媒体及红人引流，精确平台流量' },
        { path: '', title: '合规站外促销', text: '多种渠道遍布全球的真实买家，确保买家身份和购买流程符合亚马逊政策' },
        { path: '', title: '海量营销红人', text: '海量社交网红和KOI粉丝等，为商品带来高权重真实review测评' },
        { path: '', title: '快速提升排名', text: '快速提升排名，打造爆款' },
        { path: '', title: '保障隐私安全', text: '成熟稳定的风控运作机制，保障卖家用户信息的隐私安全。' },
        { path: '', title: '带动产品销量', text: '遵循亚马逊的算法规则，高效提升产品排名，增加销量。' },
        { path: '', title: '海量真实买家', text: '100%海外真实用户，100,000+海外真实买家' }
      ],
      myquestionList: [
        { name: '' },
        { name: '' },
        { name: '' },
        { name: '' }
      ]
    }
  }

}
</script>

<style lang="less" scoped>
.sellerCenter {
  .navbar {
    position: sticky;
    top: 0;
    box-shadow: 0 0 10px #ccc;
    background-color: white;
    height: 50px;
    padding: 0 60px;
    display: flex;
    justify-content: space-between;
    .navbarLeft {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .navbarLogo {
        img {
          height: 40px;
          width: auto;
        }
      }
      span {
        font-size: 14px;
        margin-left: 10px;
      }
    }
    .navbarRight {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 16px;
      div {
        padding: 0 10px;
        cursor: pointer;
        &:nth-child(1) {
          cursor: auto;
        }
      }
    }
  }
  .banner {
    background: url("../../assets/img/test2.png") no-repeat center;
    background-size: cover;
    padding: 0 200px;
    .bannerBox {
      width: 50%;
      color: white;
      text-align: left;
      h1 {
        font-size: 72px;
        padding: 100px 0 30px 0;
      }
      h2 {
        font-size: 24px;
        padding-bottom: 50px;
        font-weight: 100;
      }
      p {
        font-size: 16px;
        line-height: 30px;
      }
      .bannerBtn {
        padding: 100px 0 50px 0;
        .el-button {
          background-color: transparent;
          border-color: white;
          color: white;
          font-size: 24px;
          padding: 8px 50px;
          line-height: 36px;
          margin-right: 20px;
          &:hover {
            background-color: white;
            color: #FF992A;
          }
        }
      }
    }
  }
  .reasonBox {
    color: black;
    .row {
      margin: auto;
      width: 70%;
      display: flex;
      justify-content: space-between;
      .rowLeft {
        width: 40%;
        padding: 0 10px;
        display: flex;
        align-items: center;
        img {
          width: 100%;
          height: auto;
        }
      }
      .rowRight {
        padding: 0 10px;
        width: 60%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .rightBox {
          border: 1px solid #ECECEC;
          width: 40%;
          padding: 0 15px 10px 15px;
          margin-bottom: 20px;
          .p1 {
            font-size: 18px;
            font-weight: bolder;
            display: flex;
            justify-content: flex-start;
            line-height: 70px;
            svg {
              width: 50px;
              margin: 10px;
              color: #FF992A;
            }
          }
          .p2 {
            font-size: 14px;
            line-height: 20px;
            padding-left: 40px;
            text-align: left;
            color: #666666;
          }
        }
      }
    }
  }
  .testBox {
    .mytipul {
      margin: auto;
      width: 70%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .mytipulBox {
        width: 120px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 70px;
          height: 70px;
          border-radius: 50%;
          background-color: #FFE2C5;
          svg {
            width: 35px;
            height: 35px;
            color: #FF992A;
          }
        }
        p {
          width: 100%;
          font-size: 20px;
          margin-top: 10px;
          line-height: 30px;
        }
      }
      .mytipulImg {
        padding-bottom: 40px;
      }
    }
  }
  .serviceBox {
    .myquestion {
      display: flex;
      width: 70%;
      margin: auto;
      justify-content: space-between;
      .el-collapse {
        width: 50%;
        padding: 0 15px;
      }
      /deep/ .el-collapse-item__header {
        border: 0;
        background-color: #FFE2C5;
        margin-bottom: 15px;
        padding-left: 15px;
        &:focus {
          background-color: #FF992A;
          color: white;
        }
      }
      .el-icon-user-solid {
        font-size: 25px;
        margin-right: 15px;
      }
      .myquestionImg {
        width: 50%;
        padding: 0 15px;
        img {
          width: calc(100% - 30px);
          height: auto;
        }
      }
    }
  }
  .launch {
    width: 70%;
    margin: auto;
    color: white;
    background-color: #FF992A;
    line-height: 26px;
    font-size: 16px;
    padding: 5px;
    text-align: center;
  }
  .treaty {
    width: 70%;
    margin: auto;
    text-align: center;
    margin: 50px auto;
    font-size: 16px;
    line-height: 26px;
  }
  h3 {
    margin: 50px 0;
    font-size: 40px;
    font-weight: 100;
    padding: 30px;
    color: black;
    .xian {
      height: 20px;
      margin-top: 20px;
      width: 100%;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
        height: 2px;
        background-color: #ff9933;
      }
    }
  }
}
</style>
